<template>
<transition name="slide" appear>
  <div class="popup">
      <section class="block">
        <transition name="bubble" mode="out-in" appear>
          <div v-if="!bubble2" style="transition-delay: .5s">
              <div class="bubble" >
                <p class="text">{{baseQA.question}}</p>
                <img src="../assets/chatbot/bubble.png" alt="">
              </div>
              <div @click="bubble2 = true">{{baseQA.answer[0]}}</div>
              <div @click="bubble2 = true">{{baseQA.answer[1]}}</div>
          </div>
          <div v-else-if="bubble2">
              <div class="bubble" >
                <p class="text">{{permissionQA.question}}</p>
                <img src="../assets/chatbot/bubble.png" alt="">
              </div>
              <div>{{permissionQA.answer[0]}}</div>
              <div>{{permissionQA.answer[1]}}</div>
          </div>
        </transition>
        <div class="neow">
          <img style="min-height: 300px; min-width: 300px;" class=" overflow-hidden" src="../assets/chatbot/neow.png" alt="">
        </div>
      </section>
      <!-- <section v-if="true" class="block">
        <button class="activator" @click.prevent="activate()">Active</button>
        <div class="main">
          <h4>How can we improve your experience?</h4>
          <p>Help us make this tool better for everyone. Let us know what things you'd like to be able to do with this tool or any features or information you think would be a nice addition.</p>
          <label for="">Name</label>
          <input class="fields name" :class="{'fields-active': name !== ''}" type="text" name="" id="" v-model="name" placeholder="Name"> -->
          <!-- <label for="">Message</label>
          <textarea class="fields textarea" :class="{'fields-active': message !== ''}" name="" id="" v-model="message" placeholder="Let us know your thoughts"></textarea>
          <button>Send feedback</button>
        </div> -->
      <!-- </section> -->
  </div>
</transition>
  <!-- <div class="wrap">
    <section v-if="false" class="block" :class="{ 'reveal': active }">
      <button class="activator" @click.prevent="activate()">Active</button>
      <div class="main">
        <h4>How can we improve your experience?</h4>
        <p>Help us make this tool better for everyone. Let us know what things you'd like to be able to do with this tool or any features or information you think would be a nice addition.</p>
        <!-- <label for="">Name</label>
        <input class="fields name" :class="{'fields-active': name !== ''}" type="text" name="" id="" v-model="name" placeholder="Name"> -->
        <!-- <label for="">Message</label>
        <textarea class="fields textarea" :class="{'fields-active': message !== ''}" name="" id="" v-model="message" placeholder="Let us know your thoughts"></textarea>
        <button>Send feedback</button>
      </div>
    </section> -->

</template>

<script>
export default {
  data () {
    return {
      animationBlock: false,
      animationBubble: true,
      baseQA: {
        question: 'I brought you back.',
        answer: ['Awesome', 'Thanks'],
      },
      permissionQA: {
        question: 'Can I ask you a question',
        answer: ['Go on then..', 'Leave me alone!'],
      },

      theQA: {
        question: 'Would you like to view data on your own runs?',
        answer: ['Yes please!', 'Nope']
      },
      bubble1: true,
      bubble2: false,
    }
  },
  mounted() {
    // setTimeout(() => {
    //   this.animationBubble = true;
    // }, 250);
  },
}
</script>

<style lang="scss" scoped>

.popup {
  position: fixed;
  bottom: 10px;
  right: 0;
  z-index: 10000;
}

.neow {
  max-width: 400px;
}

.bubble {
  position: relative;
  max-width: 300px;
  transition-delay: 500ms;
  .text {
    position: absolute;
    width: 100%;
    top: 38%;
    left: 50%;
    text-align: center;
    color: #000;
    transform: translate(-50%, -50%);
    max-width: 85%;
    animation-delay: 1s;
  }
}

.block {
  display: flex;
  width: 100%;
  max-width: 700px;
  justify-content: center;
  font-weight: 400;
}


.textarea {
  padding: 18px;
  height: 140px;
  margin: 24px auto 12px;
  width: 100%;
  border-radius: 8px;
  background: #496d79;
  border: 2px solid #0c242c;
}

.slide-enter-active, .slide-leave-active {
  transition: all 1s ease-in-out;
  opacity: 1;
}
.slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(60%);
}
.slide-leave-to {
  opacity: 0;
}

.bubble-enter-active, .bubble-leave-active {
  transition: opacity 0.7s ease-out;
}
.bubble-enter, .bubble-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(20px);
  opacity: 0;
}

</style>
