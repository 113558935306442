















import Vue from 'vue';
import feedbackpopup from '../feedbackPopup.vue';

export default Vue.extend({
  name: 'AdminQuestionPopup',
  components: { feedbackpopup },
  data() {
    return {
      showQuestion: false,
    };
  },
});
